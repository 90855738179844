<template>
  <div>
    <SideCircles
      class="banner-design-elements"
      width="300px"
      style="right: 80px; top:-20px; opacity: 0.2"
      fill
    />
    <Leaf
      class="banner-design-elements"
      width="100px"
      style="right: 20px; top:20px; opacity: 0.2"
      stroke
      :stroke-width="1"
    />
    <SingleCircle
      class="banner-design-elements"
      width="50px"
      style="right: 205px; top:35px; opacity: 0.3"
      fill
    />
  </div>
</template>

<script>
import Leaf from '@/components/design-elements/leaf.vue'
import SideCircles from '@/components/design-elements/sideCircles.vue'
import SingleCircle from '@/components/design-elements/singleCircle.vue'

export default {
  components: {
    Leaf,
    SideCircles,
    SingleCircle
  }
}
</script>
