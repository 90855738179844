<template>
  <v-container class="layout-container">
    <partner />
  </v-container>
</template>

<script>
import Partner from '@/modules/partner'

export default {
  components: {
    Partner
  }
}
</script>

<style scoped>
@media (min-width: 1904px) {
  .layout-container {
    max-width: 1800px;
  }
}
</style>
